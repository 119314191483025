<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mt-4 col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-2">
                  <router-link class="btn btn-secondary" to="/farmacies">
                    <i class="fas fa-angle-double-left"></i> Volver
                  </router-link>
                </div>
                <div class="col-10">
                  <h2>Informacion General de {{ farmacyInfo.pharmacyName }}</h2>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row form-group">
                <div class="col-6">
                  <img class="mx-auto" :src="picture" />
                </div>
                <div class="col-3 text-right">
                  <button
                    class="btn btn-info"
                    type="button"
                    data-toggle="modal"
                    data-target="#modalAddFineline"
                  >
                    <i class="fas fa-plus"></i> FineLine
                  </button>
                </div>
                <div class="col-3">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#modalReportPharmacy"
                    class="btn btn-warning"
                  >
                    <i class="fas fa-plus"></i> Reporte de Inventario
                  </button>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-success"
                    @click="updateNpi(data, npi)"
                  >
                    Cambiar NPI
                  </button>
                  <v-text-field label="NPI" type="number" v-model="npi">
                  </v-text-field>
                  <!-- <label for="">NPI</label>
                  <input type="number" v-model="npi" /> -->
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-success"
                    @click="updateSecureNumber(data)"
                  >
                    Agregar numero de seguridad
                  </button>
                  <v-text-field
                    label="Numero de seguridad"
                    v-model="secureNumber"
                    type="number"
                  >
                  </v-text-field>
                  <!-- <label for="">Numero de seguridad</label>
                  <input type="number" v-model="secureNumber" /> -->
                </div>
              </div>
              <div class="row">
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">
                    Correo Electrónico:
                  </p>
                  <p class="store-name">
                    {{ farmacyInfo.employee.username }}
                  </p>
                </div>
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">Telefono:</p>
                  <p class="store-name">{{ farmacyInfo.phone }}</p>
                </div>
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">Ciudad:</p>
                  <p class="store-name">{{ categories }}</p>
                </div>
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">Direccion:</p>
                  <p class="store-name">{{ farmacyInfo.address }}</p>
                </div>
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">
                    Codigo seguridad:
                  </p>
                  <p class="store-name">{{ farmacyInfo.codigoSeguridad }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">Titular:</p>
                  <p class="store-name">
                    {{ farmacyInfo.employee.fullName }}
                  </p>
                </div>
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">
                    Cuenta de Stripe:
                  </p>
                  <p class="store-name">
                    {{
                      farmacyInfo.employee.stripeAccountId
                        ? farmacyInfo.employee.stripeAccountId
                        : "No Tiene Cuenta"
                    }}
                  </p>
                </div>
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">
                    Total de Productos:
                  </p>
                  <p class="store-name">
                    {{
                      totalProductos > 1
                        ? totalProductos + " productos"
                        : totalProductos + " producto"
                    }}
                  </p>
                </div>
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">
                    Productos Activos:
                  </p>
                  <p class="store-name">
                    {{
                      totalProductosActivos > 1
                        ? totalProductosActivos + " productos"
                        : totalProductosActivos + " producto"
                    }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 form-group">
                  <p class="store-name" style="font-weight: 700;">NPI:</p>
                  <p class="store-name">{{ farmacyInfo.npi }}</p>
                </div>
              </div>
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="table-title" scope="col">Días Disponibles</th>
                    <th class="table-title" scope="col">
                      Horario de Apertura:
                    </th>
                    <th class="table-title" scope="col">Horario de Cierre:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(schedule, key) in farmacyInfo.schedule"
                    :key="key"
                  >
                    <td class="table-content">{{ schedule.day }}</td>
                    <td class="table-content">{{ schedule.start }}</td>
                    <td class="table-content">{{ schedule.end }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalAddFineline"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddFineline"
      aria-hidden="true"
      style="height: 800px;"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="font-weight: 400; font-size: 20px;"
            >
              Agregar Reporte FineLine
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>Insertar Archivo de Excell:</label>
                <input
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  class="form-control"
                  @change="cargarFilelineFile"
                />
              </div>
              <div class="scroll" style="height: 400px;  overflow: scroll;">
                <table class="table table-hover">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">Codigo</th>
                      <th scope="col">Descripcion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data of dataFineline" :key="data">
                      <td>{{ data.id }}</td>
                      <td>{{ data.descripcion }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-lg btn-block"
              :disabled="dataFineline.length == 0"
              @click="proccessFineline"
              id="btnGuardarFineline"
            >
              Procesar Reporte Fineline
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalReportPharmacy"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalReportPharmacy"
      aria-hidden="true"
      style="height: 800px;"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="font-weight: 400; font-size: 20px;"
            >
              Agregar Reporte de Inventario ({{ counter }})
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>Insertar Archivo de Excell:</label>
                <input
                  type="file"
                  accept=".xls,.xlsx,.csv,.txt"
                  class="form-control"
                  @change="cargarFileCatalogo"
                />
              </div>
              <div class="scroll" style="height: 400px;  overflow: scroll;">
                <table class="table table-hover">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">UPC</th>
                      <th scope="col">Categoria</th>
                      <th scope="col">Existencia</th>
                      <th scope="col">Precio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data of dataCatalogo" :key="data">
                      <td>{{ data.barcode }}</td>
                      <td>{{ data.fineline }}</td>
                      <td>{{ data.existencia }}</td>
                      <td>{{ data.precio }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-lg btn-block"
              :disabled="dataCatalogo.length == 0"
              @click="proccessCatalogo"
              id="btnGuardarFineline"
            >
              Procesar Reporte de Inventario
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="backdrop" class="loader">
      <div :class="loader ? 'loader-show' : ''" class="loader-container ">
        <img src="../assets/loader.svg" alt="" />
        <p class="loader-text">
          {{ counter }} registros de {{ total }} , Cargando...
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Parse from "parse";
import xlsx from "xlsx";
export default {
  name: "FarmacyDetail",
  data() {
    return {
      npi: "",
      secureNumber: "",
      backdrop: false,
      farmacyInfo: {
        codigoSeguridad: "",
        pharmacyName: "",
        schedule: [],
        phone: "",
        address: "",
        npi: "",
        employee: {
          username: "",
          stripeAccountId: "",
          fullName: ""
        }
      },
      currentUser: {},
      data: {},
      picture: "",
      storeName: "",
      email: "",
      phoneNumber: "",
      titular: "",
      address: "",
      SundayAM: null,
      SundayPM: null,
      MondayAM: null,
      MondayPM: null,
      TuesdayAM: null,
      TuesdayPM: null,
      WednesdayAM: null,
      WednesdayPM: null,
      ThursdayAM: null,
      ThursdayPM: null,
      FridayAM: null,
      FridayPM: null,
      SaturdayAM: null,
      SaturdayPM: null,
      schedule: null,
      categories: null,
      dataInfo: null,
      superadmin: false,
      totalProductos: 0,
      totalProductosActivos: 0,
      dataFineline: [],
      dataCatalogo: [],
      userId: "",
      total: 0,
      counter: 0
    };
  },
  mounted() {
    // if (
    //   Parse.User.current().attributes.isAdmin &&
    //   !Parse.User.current().attributes.superAdmin
    // ) {
    //   this.$router.push("/userTabs");
    // }
    // if (
    //   !Parse.User.current().attributes.isAdmin &&
    //   !Parse.User.current().attributes.superAdmin
    // ) {
    //   this.$router.push("/");
    // }
    // if (Parse.User.current() === null) {
    //   this.$router.push("/HelloWorld");
    // }
    this.getStoreInfo();
  },
  methods: {
    updateSecureNumber(pharmacy) {
      pharmacy
        .set("secureNumber", this.secureNumber)
        .save()
        .then(() => {
          this.$swal({
            title: "El numero de seguridad fue actualizado",
            icon: "success"
          });
        })
        .catch(err => {
          this.$swal({
            title: "Ha ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    updateNpi(pharmacy, npi) {
      pharmacy.set("NPI", npi);
      pharmacy
        .save()
        .then(() => {
          this.$swal({
            title: "El NPI fue actualizado",
            icon: "success"
          });
        })
        .catch(err => {
          this.$swal({
            title: "Ha ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    async operarProducto(productos) {
      this.counter = 0;
      this.total = this.dataCatalogo.length;
      this.backdrop = true;
      this.laoder = true;
      let store = new Parse.Query("Store");
      store.include("user");
      store.include("objectId");
      store.include("employee");
      store.equalTo("objectId", this.$route.params.id);
      store.first().then(async usuario => {
        for (let b = 0; b < productos.length; b++) {
          for (let a = 0; a < productos[b].length; a++) {
            let producto = new Parse.Query("Products");
            producto.equalTo("barcode", productos[b][a].barcode);
            producto.equalTo(
              "storeId",
              Parse.Object.extend("Store")
                .createWithoutData(this.$route.params.id)
                .toPointer()
            );
            producto.equalTo(
              "employee",
              Parse.Object.extend("_User")
                .createWithoutData(usuario.get("employee").id)
                .toPointer()
            );
            let product = await producto.first();
            if (product) {
              product.set("barcode", productos[b][a].barcode);
              product.set("upc", productos[b][a].barcode);
              product.set("upc11", parseInt(productos[b][a].barcode));
              product.set("Price", productos[b][a].precio);
              product.set("SubCategory", productos[b][a].fineline);
              product.set("quantity", productos[b][a].existencia);
              product.set(
                "outOfStock",
                parseInt(productos[b][a].existencia) < 1 ? true : false
              );
              await product.save();
            } else {
              let newProduct = Parse.Object.extend("Products");
              let newProd = new newProduct();
              newProd.set(
                "storeId",
                Parse.Object.extend("Store")
                  .createWithoutData(this.$route.params.id)
                  .toPointer()
              );
              newProd.set(
                "employee",
                Parse.Object.extend("_User")
                  .createWithoutData(usuario.get("employee").id)
                  .toPointer()
              );
              newProd.set("barcode", productos[b][a].barcode);
              newProd.set("upc", productos[b][a].barcode);
              newProd.set("upc11", parseInt(productos[b][a].barcode));
              newProd.set("Price", productos[b][a].precio);
              newProd.set("preferenciaPrice", productos[b][a].precio);
              newProd.set("SubCategory", productos[b][a].fineline);
              newProd.set("quantity", productos[b][a].existencia);
              newProd.set("Name", "Producto");
              newProd.set(
                "outOfStock",
                parseInt(productos[b][a].existencia) < 1 ? true : false
              );
              newProd.set("productAdvice", 10);
              newProd.set("offer", 0);
              newProd.set("IsOffer", false);
              newProd.set("shopper", 0);
              newProd.set("esShopper", false);
              newProd.set("ivuPercent", 0);
              newProd.set("applyIvu", false);
              newProd.set("pictureRotate", 0);
              await newProd.save();
            }
            this.counter++;
          }
        }
        this.backdrop = false;
        this.laoder = false;
      });
    },
    operarCategoria(categoria) {
      let store = new Parse.Query("Store");
      store.include("user");
      store.include("objectId");
      store.include("employee");
      store.equalTo("objectId", this.$route.params.id);
      store.first().then(usuario => {
        let category = new Parse.Query("SubCategory");
        category.equalTo("subCategory", categoria.nombre);
        category.equalTo(
          "storeId",
          Parse.Object.extend("Store")
            .createWithoutData(this.$route.params.id)
            .toPointer()
        );
        category.equalTo(
          "employeeId",
          Parse.Object.extend("_User")
            .createWithoutData(usuario.get("employee").id)
            .toPointer()
        );
        category.first().then(cat => {
          if (cat) {
            cat.set("subCategory", categoria.nombre);
            cat.set("finelineCode", categoria.fineline.toString());
            cat.save();
          } else {
            let newCategory = Parse.Object.extend("SubCategory");
            let newCat = new newCategory();
            newCat.set("subCategory", categoria.nombre);
            newCat.set("finelineCode", categoria.fineline.toString());
            newCat.set(
              "employeeId",
              Parse.Object.extend("_User")
                .createWithoutData(usuario.get("employee").id)
                .toPointer()
            );
            newCat.set(
              "storeId",
              Parse.Object.extend("Store")
                .createWithoutData(this.$route.params.id)
                .toPointer()
            );
            newCat.save();
          }
        });
      });
    },
    cargarFileCatalogo() {
      this.dataCatalogo = [];
      let selectedFile = event.target.files[0];
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = xlsx.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = xlsx.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            for (let row of rowObject) {
              this.dataCatalogo.push({
                barcode: "0" + row.UPC,
                fineline: row["Fineline Name"],
                existencia: row.OnHand.toString(),
                precio: row.Retail.toString()
              });
            }
          });
        };
      }
      // lector.readAsText(archivo);
      // let data=[{
      // "name":"jayanth",
      // "data":"scd",
      // "abc":"sdef"
      // }];
      // xlsx.utils.json_to_sheet(data, 'out.xlsx');
      // if (selectedFile) {
      //     let fileReader = new FileReader();
      //     fileReader.readAsBinaryString(selectedFile);
      //     fileReader.onload = (event)=> {
      //         let data = event.target.result;
      //         let workbook = xlsx.read(data,{type:"binary"});
      //         workbook.SheetNames.forEach((sheet) => {
      //             let rowObject = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
      //             for(let row of rowObject){
      //                 if(row.Column3 * 1){
      //                     this.dataCatalogo.push({
      //                         barcode: row.Column1,
      //                         nombre: row.Column2,
      //                         fineline: row.Column3,
      //                         existencia: row.Column4,
      //                         precio: row.Column6,
      //                     });
      //                 }
      //             }
      //         });
      //     }
      // }
      // this.backdrop = false;
      // this.loader = false;
    },
    cargarFilelineFile() {
      this.backdrop = true;
      this.loader = true;
      let selectedFile = event.target.files[0];
      // let data = [
      //   {
      //     name: "jayanth",
      //     data: "scd",
      //     abc: "sdef"
      //   }
      // ];
      // xlsx.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = xlsx.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = xlsx.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            for (let row of rowObject) {
              if (row["__EMPTY_1"] * 1) {
                this.dataFineline.push({
                  id: row["__EMPTY_1"],
                  descripcion: row["__EMPTY"]
                });
              }
            }
          });
        };
      }
      this.backdrop = false;
      this.loader = false;
    },
    proccessCatalogo() {
      this.backdrop = true;
      this.loader = true;
      let catalogos = [];
      let dimensiones = Math.round(this.dataCatalogo.length / 100) + 1;
      for (let i = 0; i < this.dataCatalogo.length; i += dimensiones) {
        let dimension = this.dataCatalogo.slice(i, i + dimensiones);
        catalogos.push(dimension);
      }
      this.operarProducto(catalogos);
    },
    proccessFineline() {
      alert(
        "Archivo Fineline se procesara a farmacia " +
          this.data.get("Name") +
          " para la actualizacion categorias, una vez se quiten los registros de la tabla indica que ya ha terminado el proceso"
      );
      for (let fineline of this.dataFineline) {
        this.operarCategoria({
          nombre: fineline.descripcion,
          fineline: fineline.id
        });
      }
      this.dataFineline = [];
    },
    getStoreInfo() {
      const query = new Parse.Query("Store");
      const queryproducts = new Parse.Query("Products");
      const queryproductsactivos = new Parse.Query("Products");
      query.include("employee");
      query.equalTo("objectId", this.$route.params.id);
      query.first().then(result => {
        this.data = result;
        this.farmacyInfo.codigoSeguridad = result.id;
        this.farmacyInfo.pharmacyName = result.get("Name");
        this.farmacyInfo.employee.username = result
          .get("employee")
          .get("username");
        this.farmacyInfo.schedule = result.get("Schedule");
        this.farmacyInfo.npi = result.get("NPI");
        this.farmacyInfo.address = result.get("Address");
        this.farmacyInfo.phone = result.get("Phone");
        this.npi = result.get("NPI");
        this.currentUser = result.get("employee");
        this.farmacyInfo.employee.stripeAccountId = result
          .get("employee")
          .get("stripeAccountId");
        this.farmacyInfo.employee.fullName = result
          .get("employee")
          .get("fullName");
        this.picture = result.get("storePicture")
          ? result.get("storePicture").url()
          : "https://www.coopharma.app/img/logo-coopharma.e5f23e05.png";
      });

      queryproducts.equalTo(
        "storeId",
        Parse.Object.extend("Store")
          .createWithoutData(this.$route.params.id)
          .toPointer()
      );
      queryproducts.count().then(products => {
        this.totalProductos = products;
      });

      queryproductsactivos.equalTo(
        "storeId",
        Parse.Object.extend("Store")
          .createWithoutData(this.$route.params.id)
          .toPointer()
      );
      queryproductsactivos.equalTo("outOfStock", false);
      queryproductsactivos.count().then(actived => {
        this.totalProductosActivos = actived;
      });
    },
    proccessFile(event) {
      let selectedFile;
      selectedFile = event.target.files[0];
      let data = [
        {
          name: "jayanth",
          data: "scd",
          abc: "sdef"
        }
      ];
      xlsx.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = xlsx.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = xlsx.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            this.dataInfo = rowObject;
          });
        };
      }
    }
  }
};
</script>

<style scoped>
.loader-container {
  background: #fff;
  width: 400px;
  height: 300px;
  transition: 0.3s all ease;
  transform: scale(0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loader-show {
  transform: scale(1) !important;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.loader-text {
  font-family: "segoe ui";
  font-style: italic;
  font-size: 20px;
}
.darkBackground {
  background-color: #1e1e1e !important;
}
.darkFont {
  color: #1e1e1e !important;
}
.navbar,
.navbar-expand-lg,
.navbar-light,
.bg-light {
  background: #e0e0e0 !important;
}

.nav-item {
  font-family: "Montserrat", sans-serif;
}

.mx-auto {
  border-radius: 10px;
  width: 200px;
  height: 200px;
  margin: 20px 40% 30px 40%;
}

.nav-link {
  color: black !important;
  font-weight: 200;
  font-size: 13px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.5s;
}

.nav-link:hover {
  color: #0e9390 !important;
  margin-left: 20px;
  margin-right: 20px;
}

.logo-link {
  position: absolute;
  left: 20px;
  top: 10px;
}

.logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}

.navbar-toggler {
  border: 2px solid rgb(184, 184, 184) !important;
}

.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}

.icon3 {
  color: #029693;
  margin-right: 10px;
}

.nav-link2 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
}

.nav-position {
  padding-top: 30px;
}

.card-title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.table-title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 13px;
}
.table-content {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 13px;
}

.store-name {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
}
</style>
