<template>
  <div>
    <NavBarAdmin></NavBarAdmin>
    <div>
      <FarmacyDetail></FarmacyDetail>
    </div>
  </div>
</template>
<script>
import NavBarAdmin from "../../components/NavBarAdmin.vue";
import FarmacyDetail from "../../components/FarmacyDetail.vue";
export default {
  name: "FarmacyDetails",
  components: {
    NavBarAdmin,
    FarmacyDetail
  }
};
</script>
